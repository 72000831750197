/* Style fixes for template */

.panel-bold h3 {
    color: #ffffff;
    font-weight: 100;
}

/* Theme overrides */
.app-code-little {
    padding-top: 0;
    padding-bottom: 16px;
}

.code-callout > blockquote > p {
    font-weight: bold;
}

/* Style extensions for template */
.alert.alert-default {
    background-color: #dddddd;
}

.panel-warning p a {
    color: rgba(86, 86, 86, 0.7);
}

.panel-warning.panel-bold p {
    color: rgba(255, 255, 255, 0.8);
}

/* Application styles */

.pre-scrollable-fixed {
    min-height: 340px;
    white-space: pre-wrap;
    font-size: 11px;
}

.top-nav {
    background-color: #351252;
}

/* footer block */
.app-footer {
    background-color: #351252;
}

.feature-image-md {
    height: 280px;
}

.price-item {
    padding-left: 8px;
    padding-right: 8px;
}

.price-item > h6 {
    color: #bec8ce;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 12px;
}

.price-item > div {
    margin-top: 6px;
}

.featured-list-item-icon {
    color: #46b8da;
}

.page-subsection {
    padding-bottom: 9.5px;
    margin: 35px 0 17px;
    border-bottom: 1px solid #eeeeee;
}

.text-small {
    font-size: 70%;
}

.text-italic {
    font-style: italic;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 767px) {
    .m-b-0-c-xs {
        margin-bottom: 0 !important;
    }

    .m-b-sm-c-xs {
        margin-bottom: 10px !important;
    }

    .m-b-md-c-xs {
        margin-bottom: 15px !important;
    }

    .m-t-sm-c-xs {
        margin-top: 10px;
    }

    .m-t-lg-c-xs {
        margin-top: 30px;
    }

    .m-t-md-c-xs {
        margin-top: 20px;
    }

    .m-t-lg-c-xs {
        margin-top: 30px;
    }

    .product-intro-title {
        font-size: 20px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
    .m-t-sm-c-sm {
        margin-top: 10px;
    }

    .m-t-md-c-sm {
        margin-top: 20px;
    }

    .m-t-lg-c-sm {
        margin-top: 30px;
    }

    .product-intro-title {
        font-size: 28px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    .m-t-sm-c-md {
        margin-top: 10px;
    }
}

/* Large Devices */
@media only screen and (min-width: 1200px) {
    /**/
}

.m-x-sm {
    margin-left: 10px;
    margin-right: 10px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.p-x-xs {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.p-t-sm {
    padding-top: 20px !important;
}

.p-t-xs {
    padding-top: 10px !important;
}

.m-x-xs {
    margin-left: 5px;
    margin-right: 5px;
}

.m-y-xs {
    margin-top: 5px;
    margin-bottom: 5px;
}
