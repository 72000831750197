.separator-heavy {
    border-color: rgba(255, 255, 255, 0.3);
}

.lead-link-inverse {
    color: #fff;
    margin-left: 15px;
}

.lead-link-inverse:hover {
    color: #97d1ee;
}
