.page-block-min-height {
    min-height: 50vh;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 767px) {
    .page-block-sm {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .page-block {
        margin: 40px 10px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
    .page-block-sm {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .page-block {
        margin: 60px 30px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    .page-block-sm {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .page-block {
        margin: 100px 0;
    }
}
